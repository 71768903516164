import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { useEffect } from 'react'

export const initSentry = () => {
  if (!(import.meta.env.MODE === 'production' || import.meta.env.DEV)) {
    return
  }

  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: 'https://e9b2e0ecc3a0dac3ddecd34ac6f39ab9@o4508014572077056.ingest.us.sentry.io/4508020892434432',
    // debug: import.meta.env.DEV,
    maxValueLength: 500,
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.origin,
          import.meta.env.MODE === 'production'
            ? 'pharmacy-insurance-prod.kerahealth.one'
            : 'pharmacy-insurance-dev.kera-dev.app',
          /^https:\/\/pharmacy-insurance-prod\.kerahealth\.one/,
        ],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],

    // Tracing
    tracesSampleRate: 0.5,
    profilesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, hint) => {
      if (import.meta.env.DEV) {
        // console.log(event, hint)
      }

      return event
    },
  })
}
