import PharmacyIcon from '@/components/icons/PharmacyIcon'
import SuraWithLabelIcon from '@/components/icons/SuraWithLabelIcon'
import TickCircleIcon from '@/components/icons/TickCircleIcon'
import { WHATSAPP_NUMBER } from '@/config'
import { useOrganizationDetails } from '@/features/make-payment/api/getOrganizationDetails'
import { useDecodeToken } from '@/features/token/api/decode-token'
import { ErrorEnum, getApiErrorDetail } from '@/utils/errors'
import { zodResolver } from '@hookform/resolvers/zod'
import { Badge, Box, Button, Group, NumberInput, rem, Stack, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { captureException } from '@sentry/react'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { z } from 'zod'
import { useMakePayment } from '../api/makePayment'
import { ConfirmPaymentDrawer } from './ConfirmPaymentDrawer'
import { openLimitExceedModal } from './openLimitExceedModal'

const LIMIT = 35_000

const createFormSchema = (t: TFunction) => {
  return z.object({
    amount: z
      .number({
        required_error: t('amount_is_required'),
        invalid_type_error: t('amount_is_required'),
      })
      .min(1, { message: t('amount_cannot_be_0') })
      .max(999_999_999, {
        message: t('maximum_amount_allowed_exceeded'),
      }),
  })
}

type FormValues = z.infer<ReturnType<typeof createFormSchema>>

export const MakePaymentB2BPage = () => {
  const { t } = useTranslation()
  const isKeyboardOpen = useDetectKeyboardOpen()

  const [confirmDrawerOpened, { open: openConfirmDrawer, close: closeConfirmDrawer }] = useDisclosure(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const isPaymentCompleted = searchParams.get('success') === 'true'

  const token = useParams().token as string
  const { data: decodedToken } = useDecodeToken(token)
  const { data: organizationDetails } = useOrganizationDetails({})

  const formSchema = useMemo(() => createFormSchema(t), [t])
  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const makePaymentMutation = useMakePayment()

  const onSubmit = handleSubmit((data) => {
    const isDigitizedLimitExceeded =
      decodedToken?.is_digitized && decodedToken.approved_amount && data.amount > decodedToken.approved_amount
    const isNonDigitizedLimitExceeded = !decodedToken?.is_digitized && data.amount > LIMIT

    if (isDigitizedLimitExceeded || isNonDigitizedLimitExceeded) {
      openLimitExceedModal()
      return
    }

    openConfirmDrawer()
  })

  const onConfirm = (amount: number) => {
    if (!decodedToken?.claim_id || !organizationDetails?.m_id) return

    const payload = {
      isB2BPayout: true,
      token: token,
      claimId: decodedToken.claim_id,
      merchantId: organizationDetails.m_id,
      amount,
    }

    makePaymentMutation.mutate(payload, {
      onSuccess: () => {
        searchParams.set('success', 'true')
        setSearchParams(searchParams)

        closeConfirmDrawer()
      },
      onError: (_error) => {
        captureException(_error, {
          level: 'error',
          extra: {
            description: 'Error making payment',
            payload,
          },
        })
        const error = getApiErrorDetail(_error)

        if (error.error_code === ErrorEnum.SIMILAR_TRANSACTION_ONGOING) {
          toast.error(t(ErrorEnum.SIMILAR_TRANSACTION_ONGOING))
        } else {
          toast.error(t('error_while_making_payment'))
        }
      },
    })
  }

  if (!organizationDetails) return null

  return (
    <Stack flex="1" component="form" onSubmit={onSubmit} pos="relative" pb={isPaymentCompleted ? 104 : 0}>
      <FormProvider {...formMethods}>
        <ConfirmPaymentDrawer opened={confirmDrawerOpened} onClose={closeConfirmDrawer} onConfirm={onConfirm} />
        <Stack mt={32} align="center">
          <SuraWithLabelIcon />
          <Stack mt={isKeyboardOpen ? 24 : 56} align="center">
            <PharmacyIcon />
            <Badge variant="outline" fw={500}>
              {organizationDetails.name}
            </Badge>
          </Stack>
          {!isPaymentCompleted && (
            <Stack w="100%" mt={32} gap={0}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <NumberInput
                    inputMode="decimal"
                    error={errors.amount?.message as string}
                    w="100%"
                    hideControls
                    label={t('enter_total_amount')}
                    thousandSeparator=" "
                    {...field}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        {isPaymentCompleted && (
          <Stack my="auto" align="center" gap={0}>
            <TickCircleIcon />
            <Title mt={8} order={2} size={28} fw={700} c="alerts-green">
              {t('success')}
            </Title>
            <Text mt={16} fw={600} c="base-black" ta="center">
              {t('success_paid')}
            </Text>
            <Text mt={16} size="sm" ta="center">
              {t('check_whatsapp_for_receipt')}
            </Text>
          </Stack>
        )}
        <Group mt={isKeyboardOpen ? '0' : 'auto'}>
          {isPaymentCompleted ? (
            <Box
              maw="var(--container-size-xs)"
              mx="auto"
              px={16}
              pb={32}
              pt={16}
              bg="white"
              className="fixed bottom-0 left-0 right-0"
              style={{ boxShadow: '0px -4px 20px 0px #0000000A' }}>
              <Button
                component="a"
                href={`https://wa.me/${WHATSAPP_NUMBER}?text=%20`}
                target="_blank"
                color="alerts-green"
                h={rem(55)}
                fullWidth>
                {t('back_to_home')}
              </Button>
            </Box>
          ) : (
            <Button type="submit" fullWidth>
              {t('finish')}
            </Button>
          )}
        </Group>
      </FormProvider>
    </Stack>
  )
}
