import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enJSON from './locales/en.json'
import frJSON from './locales/fr.json'
import { AGENT_NUMBER_SN } from './config'

i18n.use(initReactI18next).init({
  interpolation: {
    prefix: '{',
    suffix: '}',
    defaultVariables: {
      agent_phone_number: AGENT_NUMBER_SN,
    },
  },
  resources: {
    en: {
      translation: enJSON,
    },
    fr: {
      translation: frJSON,
    },
  },
  lng: import.meta.env.DEV ? 'en' : 'fr',
})
